import { User } from "firebase/auth"
import * as React from "react"
import { BaseAction } from "../../../utils/action"
import { FirebaseContext } from "../../firebase"

export interface AuthUserState {
  authUser?: User
  initialized: boolean
}

const initialState: AuthUserState = {
  initialized: false,
}

export type AuthUserAction = BaseAction<"set_initialized" | "update_user", User>

const AuthUserContext = React.createContext<AuthUserState>(initialState)
export default AuthUserContext

const reducer = (state: AuthUserState, action: AuthUserAction) => {
  switch (action.type) {
    case "set_initialized":
      return { ...state, initialized: true }
    case "update_user":
      return { ...state, authUser: action.payload }
    default:
      throw Error(
        `AuthUserContext reducer does not support the action type: ${action.type}`
      )
  }
}

type AuthUserContextProviderProps = {
  children: React.ReactNode
}

export const AuthUserContextProvider = ({
  children,
}: AuthUserContextProviderProps) => {
  const firebase = React.useContext(FirebaseContext)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const onAuthUser = (user: User) =>
    dispatch({ type: "update_user", payload: user })
  const onNoAuthUser = () =>
    dispatch({ type: "update_user", payload: undefined })

  React.useEffect(() => {
    if (!state.initialized && firebase) {
      firebase.onAuthUserListener(onAuthUser, onNoAuthUser)
      dispatch({ type: "set_initialized" })
    }
  }, [firebase, state.initialized])

  return (
    <AuthUserContext.Provider value={state}>
      {children}
    </AuthUserContext.Provider>
  )
}
