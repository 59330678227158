import { FirebaseApp, initializeApp } from "firebase/app"
import * as React from "react"
import Firebase from "../../services/firebase"
import config from "../../config"

let firebase: Firebase

const getFirebase = (app: FirebaseApp) =>
  firebase ?? (firebase = new Firebase(app))

export const FirebaseContext = React.createContext<Firebase | null>(null)

type FirebaseContextProviderProps = {
  children: React.ReactNode
}

export const FirebaseContextProvider = ({
  children,
}: FirebaseContextProviderProps) => {
  console.log(config.firebaseConfig)
  const firebase = getFirebase(initializeApp(config.firebaseConfig))
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebaseService = () => {
  const context = React.useContext(FirebaseContext)
  if (context === undefined || context === null) {
    throw new Error("useAuthUser must be used within a FirebaseProvider")
  }
  return context
}
