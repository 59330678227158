import env from "env-sanitize"
import { config } from "dotenv"

const isStaging = env("STAGING", x => x.asBoolean(), false)
const environment = process.env.NODE_ENV

if (isStaging) {
  config({ path: `.env.${environment}.staging` })
} else {
  config({ path: `.env.${environment}` })
}

const appConfig = {
  isStaging: isStaging,
  metadata: {
    siteUrl: process.env.GATSBY_SITE_URL ?? "",
  },
  mail: {
    fromEmail:
      process.env.MAILS_COLLECTION_FROM_EMAIL ?? "mari@pawsandall.co.za",
  },
  google: {
    mapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? "",
  },
  firebaseConfig: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
  },
}

export default appConfig
